<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Mailer" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :class="{'is-invalid': isTabInvalid(title)}"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Subject *"
                  v-model="subject"
                  @input="delete errors.subject"
                  :error="!!errors.subject"
                  :error-messages="errors.subject"
                />
                <div style="margin-bottom: 15px;">
                  <span>Template</span>
                  <ckeditor v-model="template" :editor="editor" :config="editorConfig"></ckeditor>
                  <span>POST-param name in double brackets will be changed into their values.</span>
                  <span v-if="errors.template" style="color: red;">{{ errors.template[0] }}</span>
                </div>
                <va-input
                  label="Send from *"
                  v-model="sendFrom"
                  @input="delete errors.send_from"
                  :messages="['Often, the same as in connection settings']"
                  :error="!!errors.send_from"
                  :error-messages="errors.send_from"
                />
                <va-input
                  label="Send to *"
                  v-model="sendTo"
                  @input="delete errors.send_to"
                  :error="!!errors.send_to"
                  :error-messages="errors.send_to"
                />
                <va-input
                  label="Send to cc"
                  type="textarea"
                  v-model="sendToCc"
                  @input="delete errors.send_to_cc"
                  :messages="['Each address from new line']"
                  :error="!!errors.send_to_cc"
                  :error-messages="errors.send_to_cc"
                />
                <va-input
                  label="Send to bcc"
                  type="textarea"
                  v-model="sendToBcc"
                  @input="delete errors.send_to_bcc"
                  :messages="['Each address from new line']"
                  :error="!!errors.send_to_bcc"
                  :error-messages="errors.send_to_bcc"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="SMTP host *"
                  v-model="smtpHost"
                  @input="delete errors.smtp_host"
                  :messages="['smtp.gmail.com']"
                  :error="!!errors.smtp_host"
                  :error-messages="errors.smtp_host"
                />
                <va-input
                  label="SMTP port *"
                  v-model.number="smtpPort"
                  @input="delete errors.smtp_port"
                  :messages="['465']"
                  :error="!!errors.smtp_port"
                  :error-messages="errors.smtp_port"
                />
                <va-input
                  label="SMTP encryption *"
                  v-model="smtpEncryption"
                  @input="delete errors.smtp_encryption"
                  :messages="['tls/ssl']"
                  :error="!!errors.smtp_encryption"
                  :error-messages="errors.smtp_encryption"
                />
                <va-toggle label="Auth" small v-model="auth"/>
                <va-input
                  label="SMTP username"
                  v-model="smtpUsername"
                  @input="delete errors.smtp_username"
                  :error="!!errors.smtp_username"
                  :error-messages="errors.smtp_username"
                />
                <va-input
                  label="SMTP password"
                  type="password"
                  v-model="smtpPassword"
                  @input="delete errors.smtp_password"
                  :error="!!errors.smtp_password"
                  :error-messages="errors.smtp_password"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      label: '',

      subject: '',
      template: '',
      sendFrom: '',
      sendTo: '',
      sendToCc: '',
      sendToBcc: '',

      smtpHost: '',
      smtpPort: '',
      smtpEncryption: '',
      auth: false,
      smtpUsername: '',
      smtpPassword: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Message', 'Connection'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)
      if (tab === 0) {
        return !!this.errors.label
      } else if (tab === 1) {
        return !!this.errors.subject || !!this.errors.template || !!this.errors.send_from || !!this.errors.send_to || !!this.errors.send_to_cc || !!this.errors.send_to_bcc
      } else if (tab === 2) {
        return !!this.errors.smtp_host || !!this.errors.smtp_port || !!this.errors.smtp_encryption || !!this.smtp_username || !!this.smtp_password
      }
      return false
    },
    submit () {
      const data = {
        label: this.label,

        subject: this.subject,
        template: this.template,
        send_from: this.sendFrom,
        send_to: this.sendTo,
        send_to_cc: this.sendToCc,
        send_to_bcc: this.sendToBcc,

        smtp_host: this.smtpHost,
        smtp_port: this.smtpPort,
        smtp_encryption: this.smtpEncryption,
        auth: this.auth,
        smtp_username: this.smtpUsername,
        smtp_password: this.smtpPassword,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/mailer/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-settings-mailer' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/mailer?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-settings-mailer' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/mailer/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.subject = response.data.subject
            this.template = response.data.template

            this.smtpHost = response.data.smtp_host
            this.smtpPort = response.data.smtp_port
            this.smtpEncryption = response.data.smtp_encryption
            this.auth = !!response.data.auth
            this.smtpUsername = response.data.smtp_username
            this.smtpPassword = response.data.smtp_password

            this.sendFrom = response.data.send_from
            this.sendTo = response.data.send_to
            this.sendToCc = response.data.send_to_cc
            this.sendToBcc = response.data.send_to_bcc
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
